import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/aboutUs";
import ContactPage from "./pages/contactPage";
import OurServices from "./pages/ourservices";
import TempPage from "./pages/404";
import Blogs from "./pages/Blogs";
import LandingPage from "./pages/landingPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteUser from "./pages/DeleteAccount";
// import AccountingBookkeepingServices from "./pages/AccountingBookkeepingServices";
import MoreDetails from "./components/partials/MoreDetails"
//These imports declare each page as variable to be rendered using react router

//Declares routes and the elements rendered
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          
          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/deleteuser" element={<DeleteUser />}></Route>
          {/* <Route path="/moreServices" element={<AccountingBookkeepingServices />}></Route> */}
          <Route path="/moredetails" element={<MoreDetails />}></Route>
          {/* Route to Home Page */}
          <Route path="/" element={<Home />}></Route>
          {/* Route to about us page */}
          <Route path="/aboutus" element={<AboutUs />}></Route>
          {/* Route to Our services page */}
          <Route path="/ourservices" element={<OurServices />}></Route>
          <Route path="/blogs" element={<Blogs />}></Route>
          {/* Route to contact page */}
          <Route path="/contactpage" element={<ContactPage />}></Route>
          {/* If a unknown URL is accessed, Redirect to /404 */}
          <Route path="/comingsoon" exact element={<LandingPage />}></Route>
          <Route path="*" exact element={<Navigate to="/404" />}></Route>
          {/* /404 page. FourOFour component is rendered with a link to return Home */}
          <Route path="/404" exact element={<TempPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
//Exports App component
export default App;
