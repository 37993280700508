import React from "react";
//Imports white vcs-logo as usable JSX variable
import { Link } from "react-router-dom";
//Creates footer component
function Footer({ isOnLandingPage }) {
  return (
    <div id="FooterDiv" style={{marginTop: "auto"}}>
      <div
        style={{
          background: "#252525",
          color: "#fff",
          width: "100%",
          heigh: "100%"
        }}
      >
        <div className="container">
          <div className="row">
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                paddingTop: 10,
              }}
              className="col-lg-4 col-sm-12 col-xs-12"
            >
              <h5>Contact Info</h5>
              <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                <li>(800) 379-7426 Toll Free</li>
                <li>(951) 929-3800 Local</li>
                <li>(951) 929-3899 Fax</li>
              </ul>
            </div>
            <div
              style={{
                paddingTop: 10,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 0,
                display: "flex",
              }}
              className="col-lg-4 col-sm-12 col-xs-12"
            >
              <img
                src="https://ik.imagekit.io/39dbkqr1gb/tr:w-200/VCSWhite.webp"
                alt="vcs"
              />
            </div>
            <div
              className="col-lg-4 col-sm-12 col-xs-12"
              style={{
                paddingTop: 10,
                textAlign: "center",
              }}
            >
              <h5>Mailing Address</h5>
              <ul
                style={{
                  listStyle: "none",
                  paddingLeft: 0,
                }}
              >
                <li>PO Box 2747</li>
                <li>Hemet, California</li>
                <li>92546</li>
              </ul>
            </div>
          </div>
        </div>
        {!isOnLandingPage ? (
          <div
            style={{
              background: "#252525",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link to="/comingsoon">Mobile App Coming Soon</Link>
          </div>
        ) : null}

        <div className="footer-copyright">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>Copyright VCS Billing © 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
//Exports Footer component
export default Footer;
